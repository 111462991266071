<template>
  <div>
    <v-dialog
      v-model="links[1].modal"
      max-width="80%"
      style="z-index: 20000;"
    >
      <v-card style="background-color: white!important; color:black!important;">
        <v-card-title>
          <h2 style="margin-bottom: 30px;">Privacy Policy</h2>
          
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="links[1].modal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6">
          <span style="color: black!important;">
          This document provides information about the processing of personal data by Pompadur S.r.o , Hlavná 22 , Štúrovo, Slovakei 943 01 as the data controller (hereinafter referred to as the "Controller") through the Web Application operated by the Controller. Please read the following information carefully.<br><br>
          1.<br>
          The personal data of the data subjects (hereinafter referred to as the "Data Subject, Customer, User") who register and make purchases through the Web Application (https://app.bbooster.net) (hereinafter referred to as the "Web Application") and visit the website (hereinafter referred to as the "Data Subject", "Customer", "User") are processed by the Data Controller in accordance with the provisions of the 2011 Act on the Right to Informational Self-Determination and Freedom of Information. (hereinafter referred to as the "Infotv.") and Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Regulation (EC) No 95/46/EC (hereinafter referred to as the "Regulation", GDPR). <br><br>
          The Data Controller hereby informs the Data Subjects about the processing of personal data in connection with registration, purchases, sending newsletters and the processing necessarily connected with the use of https://app.bbooster.net as a website, the purposes of the processing, the storage period, the storage and transmission of the data, the principles and practices of the processing of personal data, as well as the means and possibilities for exercising the rights of the Data Subjects. <br><br>
          The Data Controller reserves the right to unilaterally amend this document at any time.<br><br>
          2.<br>
          The controller of the data published on the Web Application is <br><br>
Data Controller: Pompadur S.r.O. <br>
Registered office: Hlavná 22 , Štúrovo, Slovakei 943 01<br>
E-mail: info@pompadur-sro.com<br>
Website: https://app.bbooster.net<br>
Company registration number: 53 849 663<br><br>

3. Data processing during the use of the Web Application<br><br>
3.1 Registration<br><br>
The Data Controller draws the attention of the Data Subjects to the fact that the purchase on the Web Application is subject to prior registration, however, after registration the purchase is not mandatory. If the Data Subjects start the purchase without registration, the Web Application will redirect to the registration interface where the Data Subjects will have to complete the registration.<br><br>
Please note that only one registration per e-mail address is allowed. The Data Controller reserves the right to delete registrations which are manifestly incorrect or false and, in case of doubt, to verify the authenticity of the data provided.<br><br>
The Data Controller expressly draws the attention to the fact that the Data Subjects are responsible for the accuracy and timeliness of the data. In this regard, we ask you to update the data recorded in your account in the event of any changes to your personal data. Following the modification of the data, the system will send an automatic confirmation email to the registered email address, allowing the Data Subject to finalise the modification of their data.<br><br>
Purpose of the processing.<br><br>
Legal basis for the processing: the data subject's voluntary, informed and explicit consent, which the User gives by ticking the checkbox that appears during the registration process, on the basis of the information contained in this document. Article 5 (1) (a) and Article 6 (1) (a) of the GDPR).<br><br>
Scope of the data processed:<br><br>
- For private persons: billing name, billing address, postal code, city, address, country, telephone number, email address, password.<br><br>
- for legal persons: billing name, billing address, postal code, location, address, country telephone number, email address, password, EU tax number.<br><br>
Data retention period: until the withdrawal of the data subject's consent, until the definitive deletion of the user's account, which the user may request by sending a request to the contact details of the Data Controller specified in point 2, or until the deletion of the registration by the Data Controller in the case of a manifestly erroneous or false registration, or in the case of 2 years of inactivity.<br><br>
Please note that if you make a purchase on the Web Application after registration, your account will still be deleted in the above cases, but in such case the invoices issued in connection with the purchase (and therefore the personal data contained therein) will continue to be stored as set out in section 3.2.<br><br>
Place of processing: IT tools located on the premises of the controller.<br><br>
Method of data storage: electronic.<br><br>
Data transmission: no data transmission will take place.<br><br>
Data processor: Pompadur S.r.o , Hlavná 22 , Štúrovo, Slovakei 943 01<br><br>
Data processing activities carried out by: hosting services<br><br>
Possible consequences of not providing the data: it is not possible to make a purchase on the Web Application without registration. <br><br>
3.2. Purchasing on the Web Application<br><br>
Purchases by Users on the Web Application are subject to prior registration. However, once registered on the Web Application, the purchase is not necessary. In this respect, the Data Controller will provide separate information on the processing of the User's personal data related to the two processes.e<br><br>
The purpose of data processing: is to record the User's orders from the Web Application, to confirm and fulfil the order, to issue an invoice and receipt for the purchase, and to comply with the obligation to keep the records and to provide the records.<br><br>
Legal basis for processing: processing is necessary for the performance of a contract to which the data subject is a party (Article 6(1)(b) GDPR).If the sales contract between the parties has been fulfilled (the data subject as the buyer has paid the purchase price and the Data Controller as the Seller has delivered the ordered product to the buyer, who has taken delivery of the product), the legal basis for processing is Article 169(2) of the Accounting Act. Scope of the data processed:<br><br>
Scope of the data processed:<br><br>

- for private persons: billing name, billing address postal code, city, address, country telephone number, email address, password.<br>
- for legal entities: billing name, billing address, postal code, location, address, country, telephone number, email address, password, EU tax number <br>
- card details: the controller does not store bank card details.<br><br>

Stripe amerkia financial company is responsible for the further processing and storage of the data, which can be accessed by our customers at https://stripe.com/.<br><br>

Data retention period: last day of the 8th year following the last day of the 8th year following the last day of the year of issue of the invoice, in accordance with Article 169 (2) of the Accounting Act.<br><br>

Place of data processing: IT equipment located on the premises of the Data Controller, in the case of paper documents and invoices, the Data Controller's archives.<br><br>

Transfers of data: the data concerned by the processing as defined in this Chapter are transferred to the following controllers:<br><br>

Data Controller and Data Processors:<br>
Name of Data Controller: Pompadur S.r.o. <br>
Registered office: Hlavná 22 , Štúrovo, Slovakia 943 01<br>
E-mail: info@pompadur-sro.com<br>
Website: app.bbooster.net<br>
Company registration number: 53 849 663<br><br>

3.3. Newsletter distribution, direct marketing<br><br>

Pursuant to Article 6 of Act XLVIII of 2008 on the Basic Conditions and Certain Restrictions of Economic Advertising Activities (hereinafter referred to as the Advertising Act), the User may expressly consent in advance to being contacted by the Data Controller as a service provider with advertising offers and other mailings at the contact details provided by the Data Subject. In addition, the Data Subject may, subject to the provisions of this Privacy Policy, consent to the processing of personal data by the Controller necessary for the sending of advertising offers.<br><br>

The Data Controller will not send unsolicited commercial communications and the User may unsubscribe from receiving such communications free of charge, without restriction and without giving any reason. In this case, the Data Controller shall delete all personal data necessary for sending the advertising messages from its records and shall not contact the User with further advertising offers. The User can unsubscribe from the newsletters by clicking on the link in the message.<br><br>

The purpose of data processing: sending e-mail messages containing advertising to the data subject, providing information on current information, products, promotions.<br><br>

The legal basis for the processing is the voluntary, informed and explicit consent of the data subject, which the User gives by clicking on the hyperlink in the e-mail sent to confirm the subscription to the newsletter, after subscribing to the newsletter, on the basis of the information contained in this document. 5 (1) a) and Article 6 (1) a) of the GDPR).<br><br>

Data processed: surname, first name, e-mail address.<br><br>

Data retention period: until the data subject's consent is withdrawn by clicking on the unsubscribe button at the bottom of the newsletter.<br><br>

Place of processing: IT equipment located at the premises of the Data Controller or at the premises of the data processors used by the Data Controller.<br><br>

Method of data storage: electronic.<br><br>

Transfers of data: no transfers of the data concerned by the processing under this Chapter will take place.<br><br>

Data processors:<br>
Name of data controller: Pompadur S.r.O. <br>
Registered office: Hlavná 22 , Štúrovo, Slovakia 943 01<br>
E-mail: info@pompadur-sro.com<br>
Website: app.bbooster.net<br>
Company registration number: 53 849 663<br><br>

Data processing activities of the company: sending of newsletters.<br><br>

Possible consequences of not providing the data: information letters about discounts and promotions can only be sent after the data subject has provided his/her data and given his/her consent. <br><br>

3.4.<br>
The Data Controller hereby informs the User that when downloading certain parts of the website, the web server automatically places small data files, so-called cookies ("Cookies") on the User's Device, and reads them back during the subsequent visit. In some cases, these data files are considered personal data under the Infotv. and GDPR, as the browser returns a previously saved cookie, and the cookie management service provider has the possibility to link the User's current visit to previous visits, but only with respect to its own content. <br><br>

The Web Application places a cookie on the User's device during use for two different purposes. The first of these is the TOKEN, which is essential for the operation of the Web Application and is placed by the Web Application in order to identify the logged-in User's session until log-off and thus protect the registered User's data. If you do not allow your browser to accept any cookies, including TOKEN, you will not be able to use the web application. This cookie therefore does not require the user's consent.<br><br>

In addition, the Data Controller uses Google Analytics to collect statistical data, which places cookies on your browser and thus sends data to the Data Controller about what you have visited on the site. These cookies do not store personal data, they are used to track what the data subject has done on the website.<br><br>

In addition, the Data Controller uses the online advertising program Google AdWords and, within the framework of this program, also makes use of Google's conversion tracking service. By using this service, when the User reaches a website through a Google advertisement, a cookie necessary for conversion tracking is placed on his/her computer. These cookies do not contain any personal data and therefore do not identify the User. <br><br>

The latter two cookies are set with the user's consent, and are therefore only set with this consent, which the data subject gives by clicking on the "I agree" button in the pop-up window. If you do not wish to participate either in the data collection by Google Analytics or in the conversion tracking by Google Adwords, you can opt-out by not giving your consent to the setting of these cookies in your browser.<br><br>

The User may accept or reject the use of Cookies on a case-by-case basis, or reject the use of all Cookies by setting the browser appropriately. More information on how to do this and on Cookies can be found at https://www.youronlinechoices.eu/. If the User chooses to opt-out of cookies, he or she may have limited access to certain pages of the Site and certain features or services of the Site may not function properly.<br><br>

The purposes of the processing are: to identify and distinguish users, to identify users' session, to store the data provided during the session, to prevent data loss, to identify users, to carry out web analytics measurements, to operate the Site properly, to enhance the user experience, to display advertisements to Users.<br><br>

Legal basis for data processing: the consent of the data subject, which the User gives by clicking on the "I agree" button on the pop-up cookie warning, based on the appropriate information contained in this notice. 5 (1) a) and Article 6 (1) a) of the GDPR).<br><br>

The data processed include: ID number, date, time and the page previously visited.<br><br>

Method of data storage: electronic.<br><br>

Data transfer: no data transfer will take place.<br><br>

3.5. Information on the use of the Web Application as a website<br><br>

Although we are of the opinion that the data obtained by the Data Controller in the course of the processing described in this section do not constitute personal data, in order to provide full information, we wish to record that the Data Controller collects and stores in its own system, by means of logging, statistical information in aggregate form, which is not suitable for the identification of the user. The log includes, but is not limited to, the IP address of the computer of the Data Subject's user, the time of use and the user activity. The Data Controller shall not disclose this data to third parties and may use the contents of the log solely for its own analytical purposes, to improve the user experience and for the technical development of its IT system. <br><br>

4.<br>
The Data Controller shall respect the provisions on the security of personal data, and both the Data Controller and the authorised data processor shall take all technical and organisational measures and establish the procedural rules necessary to enforce the provisions of the Information Act and the GDPR on confidentiality and security of data processing.<br><br>

The Data Controller shall take appropriate measures to protect the data processed by it against unauthorised access, alteration, disclosure, transmission, disclosure, deletion or destruction, as well as against accidental destruction or damage.<br><br>

The Data Controller shall retain the data during its processing: <br><br>

- a) confidentiality: it shall protect the information so that only those who are entitled to have access to it may do so;<br>
- b) integrity: to protect the accuracy and completeness of the information and the processing method;<br>
- (c) availability: ensures that the authorised user has effective access to the information required when he needs it and that the means to access it are available.<br><br>

The Data Controller shall adequately protect its information technology systems and networks against computer fraud, espionage, fire and flooding, viruses and computer intrusions. The Operator shall ensure security through server-level and application-level protection procedures. The Operator monitors its systems in order to record and provide evidence of any security incidents. System monitoring also allows the effectiveness of the security measures in place to be verified. The compliance of the information protection measures applied by the Data Controller is required and monitored by the Data Controller on the basis of the provisions of the contracts concluded with the data processors it uses. <br><br>

5.<br>
Rights of data subjects and enforcement<br><br>

All personal information provided by the Data Subject to the Data Controller must be true, complete and accurate in all respects.<br><br>

The Data Subject may request information about the processing of his or her personal data, and may request the rectification, erasure or withdrawal of his or her personal data, except for mandatory processing, and exercise his or her rights of retention and objection, in the manner indicated when the data were collected or by contacting the Controller at the above contact details.<br><br>

Right to information.<br><br>

The right to information can be exercised in writing by using the contact details indicated in point 2 of this notice. The data subject may also be provided with information orally at his or her request, after verification of his or her identity.<br><br>

The data subject's right of access: the data subject has the right to obtain from the controller information as to whether or not his or her personal data are being processed and, if such processing is taking place, the right to access the personal data and the following information: the purposes of the processing; the categories of personal data concerned; the recipients or categories of recipients to whom or with which the personal data have been or will be disclosed, including in particular recipients in third countries or international organisations; the envisaged period of storage of the personal data; the right to rectification, erasure or restriction of processing and the right to object; the right to lodge a complaint with a supervisory authority; information on the data sources; the fact of automated decision-making, including profiling, and clear information on the logic used and the significance of such processing and its likely consequences for the data subject. In the event of a transfer of personal data to a third country or an international organisation, the data subject is entitled to be informed of the appropriate safeguards for the transfer.<br><br>

The Data Controller shall provide the data subject with a copy of the personal data which are the subject of the processing. For additional copies requested by the data subject, the controller may charge a reasonable fee in line with the administrative costs. At the request of the data subject, the controller shall provide the information in electronic form. The controller shall provide the information within a maximum of one month of the request.<br><br>

Right of rectification.<br><br>

Right to erasure: The data subject shall have the right to obtain, upon his or her request and without undue delay, the erasure of personal data relating to him or her where one of the following grounds applies:<br><br>

- The personal data are no longer necessary for the purposes for which they were collected or otherwise processed;<br>
- the data subject withdraws the consent on the basis of which the processing was carried out and there is no other legal basis for the processing;<br>
- the data subject objects to the processing and there are no overriding legitimate grounds for the processing;<br>
- the personal data have been unlawfully processed;<br>
- the personal data must be erased in order to comply with a legal obligation under Union or Member State law to which the controller is subject;<br>
- the personal data have been collected in connection with the provision of information society services<br>

The erasure of the data may not be initiated if the processing is necessary: for the exercise of the right to freedom of expression and information; for compliance with an obligation under Union or Member State law to process personal data or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller; for public health purposes or for archiving, scientific or historical research purposes or statistical purposes in the public interest; or for the establishment, exercise or defence of legal claims.<br><br>

Right to restriction of processing:<br><br>

- the data subject contests the accuracy of the personal data, in which case the restriction shall apply for the period of time necessary to allow the accuracy of the personal data to be verified;<br>
- the processing is unlawful and the data subject opposes the erasure of the data and requests instead the restriction of their use;<br>
- the controller no longer needs the personal data for the purposes of the processing but the data subject requires them for the establishment, exercise or defence of legal claims; or<br>
- the data subject has objected to the processing; in this case, the restriction shall apply for a period of time until it is established whether the legitimate grounds of the controller override the legitimate grounds of the data subject.<br><br>

Where processing is subject to restriction, personal data, other than storage, may be processed only with the consent of the data subject or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or of an important public interest of the Union or of a Member State. The Controller shall inform the data subject in advance of the lifting of the restriction on processing.<br><br>

Right to data portability: the data subject has the right to obtain the personal data concerning him or her which he or she has provided to the controller in a structured, commonly used, machine-readable format and to transmit these data to another controller.<br><br>

Right to object: the data subject has the right to object at any time, on grounds relating to his or her particular situation, to processing of his or her personal data necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller, or necessary for the purposes of the legitimate interests pursued by the controller or by a third party, including profiling based on those provisions. In the event of an objection, the controller may no longer process the personal data, unless there are compelling legitimate grounds for doing so which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defence of legal claims. Where personal data are processed for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data concerning him or her for such purposes, including profiling, where it is related to direct marketing. In the event of an objection to the processing of personal data for direct marketing purposes, the data shall not be processed for those purposes.<br><br>

Automated decision-making in individual cases, including profiling: the data subject has the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her.<br><br>

The above right shall not apply where the processing<br><br>

- necessary for the conclusion or performance of a contract between the data subject and the controller;
- permitted by Union or Member State law applicable to the controller which also lays down appropriate measures to protect the rights and freedoms and legitimate interests of the data subject; or
- is based on the explicit consent of the data subject.<br><br>

Right of Withdrawal. Withdrawal of consent does not affect the lawfulness of processing based on consent prior to its withdrawal.<br><br>

Procedural rules. If necessary, taking into account the complexity of the request and the number of requests, this time limit may be extended by a further two months.<br><br>

The controller shall inform the data subject of the extension, stating the reasons for the delay, within one month of receipt of the request. Where the data subject has made the request by electronic means, the information shall be provided by electronic means, unless the data subject requests otherwise.<br><br>

If the controller does not act on the data subject's request, the data subject shall be informed without delay and at the latest within one month of receipt of the request of the reasons for the non-action and of the possibility for the data subject to lodge a complaint with a supervisory authority and to exercise his or her right of judicial remedy.<br><br>

The Controller shall provide the requested information and notification free of charge. Where the data subject's request is manifestly unfounded or excessive, in particular because of its repetitive nature, the controller may, taking into account the administrative costs of providing the information or information requested or of taking the action requested, charge a reasonable fee or refuse to act on the request.<br><br>

The controller shall inform each recipient to whom or with which the personal data have been disclosed of any rectification, erasure or restriction of processing that it has carried out, unless this proves impossible or involves a disproportionate effort. The controller shall inform the data subject, at his or her request, of these recipients. <br><br>

The controller shall provide the data subject with a copy of the personal data which are the subject of the processing. For additional copies requested by the data subject, the controller may charge a reasonable fee based on administrative costs. If the data subject has made the request by electronic means, the information shall be provided in electronic format, unless the data subject requests otherwise.<br><br>

Compensation and damages: any person who has suffered pecuniary or non-pecuniary damage as a result of a breach of the Regulation shall be entitled to receive compensation from the controller or processor for the damage suffered. A processor shall be liable for damage caused by the processing only if it has failed to comply with the obligations expressly imposed on processors by law or if it has disregarded or acted contrary to lawful instructions from the controller.<br><br>

Where more than one controller or more than one processor, or both controller and processor, are involved in the same processing and are liable for the damage caused by the processing, each controller or processor shall be jointly and severally liable for the total damage.<br><br>

The controller or processor shall be exempt from liability if it proves that it is not in any way responsible for the event giving rise to the damage.<br><br>

Data protection authority procedure: the data subject may lodge a complaint about the processing of his or her personal data with the National Authority for Data Protection and Freedom of Information <br><br>

- Name: National Authority for Data Protection and Freedom of Information<br>
- Registered office: 1125 Budapest, Szilágyi Erzsébet fasor 22/C.<br>
- Postal address: 1530 Budapest, PO Box 5.<br>
- Phone: 06.1.391.1400<br>
- Fax: 06.1.391.1410<br>
- E-mail:ugyfelszolgalat@naih.hu<br>
- Website:http://www.naih.hu<br>

Right to bring a case before the courts. The court shall rule on the case out of turn.<br><br>

7.<br>
If the User wishes to contact the Data Controller, he/she may do so through the contact details of the Data Controller, as specified in point 2 of the Prospectus.<br>



          </span>
        </v-card-text>
        <v-card-actions class="action-container">
          <v-btn
            color="primary"
            @click="links[1].modal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="links[0].modal"
      max-width="80%"
      style="z-index: 20000;"
    >
      <v-card style="background-color: white!important; color:black!important;">
        <v-card-title>
          <h2 style="margin-bottom: 30px;">General Terms and Conditions</h2>
          
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="links[0].modal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6">
          <span style="color: black!important;">
          1 General and scope of application<br>
          The Contractor:<br>
          Data Controller: Pompadur S.r.o.<br>
          Registered office: Hlavná 22 , Štúrovo, Slovakei 943 01<br>
          E-mail: info@pompadur-sro.com<br>
          Company registration number: 53 849 663<br><br>
          Pompadur S.r.O. is the operator of Business Booster Web Application Platform (https://app.bbooster.net), (hereinafter: BBooster) which generates so-called interest in the products of our contracting partners. Our contracting partners have access to the data of interested parties on the BBooster Internet Platform. 
The contract is concluded between Pompadur S.r.O. and the customer, who can be a natural person or a legal entity.<br><br>
2. Price and service<br><br>
2.1.The contents of the package of rooms are always interested parties who have shown a serious interest in the services of Limbic Arc.<br>
2.2.2 The content of the package is the name, email address, telephone number and the city of residence of the interested party.<br>
2.3 The product package will contain 20 names per 30-day periodicity, which will be delivered electronically to the customer in the order of the arrival of the interested parties via the BBooster platform.<br>
2.4 An interested party is defined as a person who, after the Company's online selection in several steps, shows interest and willingness to purchase the product. In all cases, interested parties know the price of the product, have basic product information knowledge and require additional information to purchase or register for the product.<br>
2.5 They can purchase the product package at any time. A period lasts 30 days from the day the product is purchased.<br>
2.6 The price of the product bundle is EUR 240,which does not include AFA for the country concerned.<br><br>
3. Obligations of the buyer<br><br>
3.1 The customer is obliged to contact by telephone the above mentioned interested parties within 48 hours after uploading the Platform and after providing the contact information (name, phone number, varos, profile).<br>
3.2 The client is obliged to contact the interested party within 48 hours after contacting the interested party and to fill in the x things recorded in our system and to provide full feedback.<br><br>
4. Guarantees<br><br>
4.1 The company makes no guarantee that the enquirer will become a customer, register or that they will change their mind about buying or enquiring within 48 hours.<br>
4.2 If it can be demonstrated that the quality of the enquirer differs to such an extent that it can be demonstrated that he/she had no serious intention to purchase the product, in which case our company will replace the enquirer with a new enquirer.  Evidence will be deemed to exist if the interested party is not available on the telephone number provided or if the number does not belong to the name of the interested party.<br>
4.3 In the event of a system failure in the Company's IT system, the Company undertakes to replace the number of interested parties to correspond to the number of interested parties described in section 2.3. In this case, the service may exceed the time period specified in 2.5 until the company reaches the number of enquiries referred to in 2.5.<br>
4.4. In the event of a complaint, the company can be contacted at info@pompadur-sro.com.<br><br>
5. Purchase of the product<br><br>
5.1.  If any of these conditions are not met, the contract will not be concluded.<br>
5.2 It is the buyer's responsibility to read the contract, the company is not obliged to provide any further information other than the contract. The company is not obliged to explain the terms of the contract in any other way.<br><br>
6. Invoicing<br><br>
After the purchase of the rooms, an invoice will be sent automatically to the email address provided by the buyer.<br><br>
7. Termination of service<br><br>
7.1 The term of this contract is 30 days and upon expiry, the contract will be automatically extended for a further period as indicated in 2.5.<br>
7.2 The customer may cancel the service at any time on the Company's account tomorrow. The company will not accept any other form of cancellation of the contract.<br>
7.3The company will inform the contracting partner of the automatic renewal by email 10 days before the start of the next periodic periodic period.<br>
          </span>
        </v-card-text>
        <v-card-actions class="action-container">
          <v-btn
            color="primary"
            @click="links[0].modal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="links[2].modal"
      max-width="80%"
      style="z-index: 20000;"
    >
      <v-card style="background-color: white!important; color:black!important;">
        <v-card-title>
          <h2 style="margin-bottom: 30px;">CUSTOMER SUPPORT</h2>
          
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="links[2].modal = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6">
          <span style="color: black!important;">
            Technical Support Phone: +43 676 6747037<br>
            Sells / Legal Support Phone: +43 676 4551951<br>
            Email: hello@bbooster.net<br><br>
            Office address: Hlavná 22 , Štúrovo, Slovakei 943 01
          </span>
        </v-card-text>
        <v-card-actions class="action-container">
          <v-btn
            color="primary"
            @click="links[2].modal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  <v-footer
    id="pages-core-footer"
    absolute
    color="transparent"
    dark
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center"
          cols="6"
          md="auto"
        >
          <a
            @click.prevent = "link.modal = !link.modal"
            class="mr-0"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2021, All rights reserved
            Pompadur S.r.O.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>


  </div>
</template>

<script>
  export default {
    name: 'PagesCoreFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'Terms of Use',
          modal: false
        },
        {
          href: '#',
          text: 'Privacy Policy',
          modal: false,
        },
        {
          href: '#',
          text: 'support',
          modal: false,
        }
      ],
    }),
  }
</script>

<style lang="sass">
  #pages-core-footer
    a
      color: #FFFFFF
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
<style scoped>
  .action-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
</style>
